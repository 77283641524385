import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';

import useStyles from './styles';

const PageSection = (props) => {
    const { children, className, style } = props;

    const classes = useStyles();

    return (
        <Box className={clsx(classes.root, className)} style={style} component="div">
            {children}
        </Box>
    );
};

PageSection.defaultProps = {
    className: '',
    style: undefined,
};

PageSection.propTypes = {
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
    style: PropTypes.objectOf(PropTypes.any),
};

export default PageSection;
