import React from 'react';
import { Link as GatsbyLink } from 'gatsby';
import * as PropTypes from 'prop-types';

const Link = (props) => {
    const { href, children, ...restProps } = props;

    const hasAbsoluteURL = href.startsWith('http:') || href.startsWith('https:') || href.startsWith('mailto:');
    const Component = hasAbsoluteURL ? 'a' : GatsbyLink;

    return (
        <Component
            to={!hasAbsoluteURL ? href : undefined}
            href={hasAbsoluteURL ? href : undefined}
            rel={hasAbsoluteURL ? 'noopener noreferrer' : undefined}
            target={hasAbsoluteURL ? '_blank' : undefined}
            {...restProps}
        >
            {children}
        </Component>
    );
};

Link.propTypes = {
    href: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
};

export default Link;
