import { makeStyles } from '@material-ui/styles';

export default makeStyles((theme) => ({
    root: {
        background: '#09255a',
        color: theme.palette.common.white,
        paddingTop: theme.spacing(8),
        paddingBottom: theme.spacing(8),
        [theme.breakpoints.down('sm')]: {
            paddingTop: theme.spacing(4),
            paddingBottom: theme.spacing(4),
        },
    },
    copyRightWrapper: {
        margin: theme.spacing(1.5, 0, 3, 0),
    },
    copyRightText: {
        fontWeight: '400',
    },
    storeButtonsWrapper: {
        marginBottom: theme.spacing(3),
        '& > *': {
            marginBottom: theme.spacing(1),
        },
    },
    menu: {
        '& a': {
            color: theme.palette.common.white,
            display: 'block',
            fontWeight: 300,
            marginBottom: theme.spacing(1),
            textTransform: 'capitalize',
            '&:hover': {
                color: theme.palette.logo.blue.light,
            },
        },
    },
    menuTitle: {
        color: '#8497bb',
        fontWeight: 600,
        marginBottom: theme.spacing(2),
        display: 'block',
    },
    bottomWrapper: {
        borderTop: '2px solid rgba(255, 255, 255, 0.32)',
        marginTop: theme.spacing(10),
        paddingTop: theme.spacing(2.5),
    },
    bottomSocialNetworksWrapper: {
        [theme.breakpoints.down('sm')]: {
            marginTop: theme.spacing(6),
        },
        '& > *': {
            width: 25,
            height: 25,
            marginLeft: theme.spacing(2),
        },
        '& a': {
            color: theme.palette.common.white,
            '&:hover': {
                color: theme.palette.logo.purple.main,
            },
        },
    },
    backToTopButtonWrapper: {
        background: '#f8faff',
        padding: theme.spacing(2),
    },
}));
