import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';

import PageFooter from '$landing-page/components/page-footer';
import PageMetadata from '$landing-page/components/page-metadata';

const PageWrapper = (props) => {
    const { pageMetaData, children, showFooter } = props;
    return (
        <Box component="div">
            <PageMetadata {...pageMetaData} />
            {children}
            {showFooter && <PageFooter />}
        </Box>
    );
};

PageWrapper.defaultProps = {
    showFooter: true,
};

PageWrapper.propTypes = {
    children: PropTypes.node.isRequired,
    showFooter: PropTypes.bool,
    pageMetaData: PropTypes.shape({
        title: PropTypes.string.isRequired,
        pathname: PropTypes.string.isRequired,
        description: PropTypes.string,
        lang: PropTypes.string,
        imageSrc: PropTypes.string,
    }).isRequired,
};

export default PageWrapper;
