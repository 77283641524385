import { makeStyles } from '@material-ui/styles';

export default makeStyles((theme) => ({
    root: {
        paddingLeft: '15%',
        paddingRight: '15%',
        [theme.breakpoints.down('md')]: {
            paddingLeft: '3%',
            paddingRight: '3%',
        },
        [theme.breakpoints.down('sm')]: {
            paddingLeft: 30,
            paddingRight: 30,
        },
    },
}));
