import { makeStyles } from '@material-ui/styles';

export default makeStyles((theme) => ({
    label: {
        justifyContent: 'flex-start',
        alignItems: 'center',
        alignContent: 'center',
        whiteSpace: 'nowrap',
        padding: 0,
        textTransform: 'none',
    },
    icon: {
        display: 'inline-block',
        marginRight: theme.spacing(1),
    },
    rootMedium: {
        padding: theme.spacing(1),
        width: 128,
    },
    labelMedium: {
        fontSize: theme.typography.pxToRem(12),
        letterSpacing: 0.64,
    },
    iconMedium: {
        fontSize: theme.typography.pxToRem(24),
        height: 24,
    },
    appleIconMedium: {
        fontSize: theme.typography.pxToRem(30),
        height: 30,
    },
    rootLarge: {
        padding: theme.spacing(1.5),
        width: 180,
    },
    labelLarge: {
        fontSize: theme.typography.pxToRem(20),
        letterSpacing: 0.15,
    },
    iconLarge: {
        fontSize: theme.typography.pxToRem(30),
        height: 30,
    },
    appleIconLarge: {
        fontSize: theme.typography.pxToRem(40),
        height: 40,
    },
    buttonTitle: {
        display: 'flex',
        flexDirection: 'column',
        fontWeight: 'bold',
        '& > span': {
            fontWeight: 300,
        },
    },
    buttonTitleMedium: {
        '& > span': {
            fontSize: theme.typography.pxToRem(8),
        },
    },
    buttonTitleLarge: {
        '& > span': {
            fontSize: theme.typography.pxToRem(11),
        },
    },
}));
