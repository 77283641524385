import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Grid, Typography, useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/styles';

import Link from '$landing-page/components/link';
import PageSection from '$landing-page/components/page-section';
import StoreButton from '$landing-page/components/store-button';

import BackToTopButton from './back-to-top-button';
import useStyles from './styles';

const query = graphql`
    query pageFooter {
        site {
            siteMetadata {
                socialNetworks {
                    id
                    url
                    icon
                }
                menu {
                    title
                    url
                    subMenu {
                        title
                        url
                    }
                }
            }
        }
        logo: file(relativePath: { eq: "logo-colorful-with-shadow.png" }) {
            childImageSharp {
                gatsbyImageData(layout: FIXED, height: 60, quality: 100, placeholder: NONE)
            }
        }
    }
`;

const PageFooter = () => {
    const classes = useStyles();
    const theme = useTheme();
    const isScreenSizeMd = useMediaQuery(theme.breakpoints.up('md'));
    const queryData = useStaticQuery(query);

    return (
        <>
            <div className={classes.backToTopButtonWrapper}>
                <BackToTopButton />
            </div>
            <Box component="div" className={classes.root}>
                <PageSection>
                    <Grid container spacing={3} wrap={isScreenSizeMd ? 'nowrap' : 'wrap'}>
                        <Grid item xs={12} md={3}>
                            <GatsbyImage image={queryData.logo.childImageSharp.gatsbyImageData} alt="Ethica Data Logo" />
                            <Grid className={classes.copyRightWrapper} container spacing={1} alignItems="center">
                                <Grid item xs={12}>
                                    <Typography variant="h6" component="div">
                                        All Rights Reserved
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="body2" className={classes.copyRightText}>
                                        {`© ${new Date().getFullYear()} Ethica Data`}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="body2" className={classes.addressText} color="primary">
                                        4109 - 295 Adelaide St W Toronto, ON, M5V 0L4 Canada
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid container className={classes.storeButtonsWrapper} direction="column">
                                <Grid item>
                                    <StoreButton store="apple" />
                                </Grid>
                                <Grid item>
                                    <StoreButton store="google-play" />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} md={10}>
                            <Grid container wrap="wrap" spacing={2} className={classes.menu}>
                                {queryData.site.siteMetadata.menu.map(
                                    (menuItem) =>
                                        menuItem?.subMenu &&
                                        menuItem.subMenu.length > 0 && (
                                            <Grid item xs={12} md={4} key={menuItem.title}>
                                                <Typography component="div" variant="h6" className={classes.menuTitle}>
                                                    {menuItem.title}
                                                </Typography>
                                                {menuItem.subMenu &&
                                                    menuItem.subMenu.map((subMenuItem) => (
                                                        <Typography
                                                            key={menuItem.title + subMenuItem.url}
                                                            component={Link}
                                                            href={subMenuItem.url}
                                                            variant="body2"
                                                        >
                                                            {subMenuItem.title}
                                                        </Typography>
                                                    ))}
                                            </Grid>
                                        )
                                )}
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container className={classes.bottomWrapper} alignItems="center">
                        <Grid item xs={12} md={6}>
                            <Typography variant="body2">Using data to make a better future</Typography>
                        </Grid>
                        <Grid
                            item
                            container
                            xs={12}
                            md={6}
                            className={classes.bottomSocialNetworksWrapper}
                            wrap="nowrap"
                            alignItems="center"
                            justify="flex-end"
                        >
                            {queryData.site.siteMetadata.socialNetworks.map((socialNetwork) => (
                                <Grid item key={socialNetwork.id}>
                                    <a href={socialNetwork.url} target="_blank" rel="noopener noreferrer">
                                        <FontAwesomeIcon icon={['fab', socialNetwork.icon]} size="lg" fixedWidth />
                                    </a>
                                </Grid>
                            ))}
                        </Grid>
                    </Grid>
                </PageSection>
            </Box>
        </>
    );
};

export default PageFooter;
