import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Fab } from '@material-ui/core';

import useStyles from './styles';

const BackToTopButton = () => {
    const classes = useStyles();

    const goBackToTop = () => {
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, and Opera
    };

    return (
        <Fab color="primary" onClick={goBackToTop} className={classes.root} size="medium" focusRipple>
            <FontAwesomeIcon icon={['fad', 'angle-up']} fixedWidth size="2x" />
        </Fab>
    );
};

export default BackToTopButton;
