import { makeStyles } from '@material-ui/styles';

export default makeStyles((theme) => ({
    root: {
        margin: theme.spacing(5, 'auto'),
        display: 'block',
        color: theme.palette.logo.blue.main,
        background: theme.palette.common.white,
        boxShadow: theme.shadows[1],
        '&:hover': {
            background: theme.palette.common.white,
            boxShadow: theme.shadows[3],
        },
    },
}));
